import React from "react";
import { Grid } from "react-md";
import Cell from "./UI/Cell";
import Section from "./UI/Section";
import Showcase from "./UI/Showcase";
import Gallery from "./Gallery";
import content from "../utils/content";

const About = () => {
  return (
    <Section id="Proizvodi" title="Proizvodi">
      <Grid>
        {content.products.map((product, idx) => {
          const { icon, label, desc, title } = product;
          return (
            <Cell key={idx} size={4} tabletSize={6} tabletOffset={1}>
              <Showcase key={label} icon={icon} label={label} title={title}>
                {desc}
              </Showcase>
            </Cell>
          );
        })}
      </Grid>
      <Gallery />
    </Section>
  );
};

export default About;
