import React from "react";
import PropTypes from "prop-types";
import keys from "../../config/keys";

const { googleMapsKey } = keys;
const { string } = PropTypes;

const Map = props => {
  const { title, src } = props;

  return (
    <iframe
      title={title}
      width="300"
      height="300"
      frameBorder="0"
      style={{ border: "0" }}
      src={`${src}${googleMapsKey}`}
      allowFullScreen
    />
  );
};

Map.proptypes = {
  rirle: string.isRequired,
  src: string.isRequired
};

export default Map;
