import React from "react";
import memoize from "memoize-one";
import { Grid, Button } from "react-md";
import ImageGallery from "react-image-gallery";
import Modal from "react-responsive-modal";
import galleryImages from "../utils/gallery";

// const allImages = shuffle(galleryImages);
const allImages = galleryImages;
const categories = allImages.reduce(
  (acc, img) => {
    const { category } = img;
    return acc.includes(category) ? acc : [...acc, category];
  },
  ["SVE"]
);

const ButtonFilters = ({ activeCategory, onFilter }) => (
  <Grid id="Gallery" className="filters buttons">
    {categories.map(category => {
      const showAllBtn = category === "SVE";
      return (
        <Button
          key={category}
          onClick={() => onFilter(category)}
          raised
          primary={showAllBtn}
          secondary={!showAllBtn}
          disabled={category === activeCategory}
        >
          {category}
        </Button>
      );
    })}
  </Grid>
);

export default class extends React.Component {
  state = {
    category: categories[0],
    isDialogOpen: false,
    currentIndex: null
  };

  /**
   * Memoizes the filtered images list based on full images list and currenttly selected category
   * @param {Object[]} imageList
   * @param {string} currentCategory
   * @returns filtered list of images
   */
  filter = memoize((imageList, currentCategory) =>
    imageList.filter(
      ({ category: imageCategory }) =>
        currentCategory === imageCategory || currentCategory === "SVE"
    )
  );

  /**
   * Sets new sleected image category
   * @param {string} category
   */
  handleFilterClick = category => {
    this.setState({ category });
  };

  onOpenModal = imageIndex => {
    this.gallery && this.gallery.slideToIndex(imageIndex);

    this.setState({ isDialogOpen: true, currentIndex: imageIndex });
  };

  onCloseModal = () => {
    this.setState({ isDialogOpen: false });
  };

  render() {
    const { category, isDialogOpen, currentIndex } = this.state;
    const images = this.filter(allImages, category);
    return (
      <div>
        <div className="gallery__title md-display-2 md-text-left">Galerija</div>
        <ButtonFilters
          activeCategory={category}
          onFilter={this.handleFilterClick}
        />
        <div className="scrolling-wrapper">
          {images.map((img, idx) => {
            const { original, thumbnail, category } = img;
            return (
              <div key={original} className="card">
                <img
                  src={thumbnail || original}
                  alt={category}
                  onClick={() => this.onOpenModal(idx)}
                />
              </div>
            );
          })}
        </div>
        <Modal
          open={isDialogOpen}
          onClose={this.onCloseModal}
          classNames={{
            modal: "gallery__modal"
          }}
          center
          showCloseIcon={false}
          focusTrapped
        >
          <ImageGallery
            items={images}
            showPlayButton={false}
            showThumbnails={false}
            startIndex={currentIndex}
            ref={el => {
              this.gallery = el;
            }}
          />
        </Modal>
      </div>
    );
  }
}
