import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Showcase = ({ icon, label, children, title }) => (
  <div className="showcase">
    {icon && <FontAwesomeIcon icon={icon} className="icon-big" />}
    {title && <div className="showcase_title">{title}</div>}
    <h4 className="md-headline md-text-uppercase md-font-semibold">{label}</h4>
    {children}
  </div>
);

export default Showcase;
