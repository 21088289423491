import React from "react";
import { Toolbar, Button } from "react-md";
import content from "../utils/content";
import Scrollchor from "react-scrollchor";

const { appTitle, sections, scrollAnimate } = content;

const Navbar = () => {
  return (
    <div>
      <Toolbar
        colored
        title={appTitle}
        // actions={sections.map(({ title }) => (
        //   <Scrollchor to={"#" + title} animate={scrollAnimate}>
        //     <Button flat children={title} />
        //   </Scrollchor>
        // ))}
      />
    </div>
  );
};

export default Navbar;
