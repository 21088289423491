 export default [{
    original: require("../assets/galerija/main/DNEVNI BORAVCI/Pictures 199.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Pictures 199_thumb.jpg"),
    category: "DNEVNI BORAVCI"
  },,{
    original: require("../assets/galerija/main/DNEVNI BORAVCI/Pictures 363.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Pictures 363_thumb.jpg"),
    category: "DNEVNI BORAVCI"
  },,{
    original: require("../assets/galerija/main/DNEVNI BORAVCI/SV263443.JPG"),
    thumbnail: require("../assets/galerija/thumbs/SV263443_thumb.JPG"),
    category: "DNEVNI BORAVCI"
  },,{
    original: require("../assets/galerija/main/DNEVNI BORAVCI/SV263449.JPG"),
    thumbnail: require("../assets/galerija/thumbs/SV263449_thumb.JPG"),
    category: "DNEVNI BORAVCI"
  },,{
    original: require("../assets/galerija/main/DNEVNI BORAVCI/WP_20141216_004.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20141216_004_thumb.jpg"),
    category: "DNEVNI BORAVCI"
  },,{
    original: require("../assets/galerija/main/DNEVNI BORAVCI/WP_20150529_003.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20150529_003_thumb.jpg"),
    category: "DNEVNI BORAVCI"
  },,{
    original: require("../assets/galerija/main/DNEVNI BORAVCI/WP_20160423_013.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20160423_013_thumb.jpg"),
    category: "DNEVNI BORAVCI"
  },,{
    original: require("../assets/galerija/main/DNEVNI BORAVCI/WP_20160607_001.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20160607_001_thumb.jpg"),
    category: "DNEVNI BORAVCI"
  },,{
    original: require("../assets/galerija/main/DNEVNI BORAVCI/WP_20161122_012.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20161122_012_thumb.jpg"),
    category: "DNEVNI BORAVCI"
  },,{
    original: require("../assets/galerija/main/DNEVNI BORAVCI/WP_20161122_013.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20161122_013_thumb.jpg"),
    category: "DNEVNI BORAVCI"
  },,{
    original: require("../assets/galerija/main/DNEVNI BORAVCI/WP_20161215_006.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20161215_006_thumb.jpg"),
    category: "DNEVNI BORAVCI"
  },,{
    original: require("../assets/galerija/main/DNEVNI BORAVCI/WP_20161215_008.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20161215_008_thumb.jpg"),
    category: "DNEVNI BORAVCI"
  },,{
    original: require("../assets/galerija/main/DNEVNI BORAVCI/WP_20170407_16_56_01_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170407_16_56_01_Pro_thumb.jpg"),
    category: "DNEVNI BORAVCI"
  },,{
    original: require("../assets/galerija/main/DNEVNI BORAVCI/WP_20170505_12_35_56_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170505_12_35_56_Pro_thumb.jpg"),
    category: "DNEVNI BORAVCI"
  },,{
    original: require("../assets/galerija/main/DNEVNI BORAVCI/WP_20170509_17_24_29_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170509_17_24_29_Pro_thumb.jpg"),
    category: "DNEVNI BORAVCI"
  },,{
    original: require("../assets/galerija/main/DNEVNI BORAVCI/WP_20170901_10_32_51_Pro (2).jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170901_10_32_51_Pro (2)_thumb.jpg"),
    category: "DNEVNI BORAVCI"
  },,{
    original: require("../assets/galerija/main/DNEVNI BORAVCI/WP_20170908_12_11_43_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170908_12_11_43_Pro_thumb.jpg"),
    category: "DNEVNI BORAVCI"
  },,{
    original: require("../assets/galerija/main/DNEVNI BORAVCI/WP_20180719_18_28_40_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180719_18_28_40_Pro_thumb.jpg"),
    category: "DNEVNI BORAVCI"
  },,{
    original: require("../assets/galerija/main/DNEVNI BORAVCI/tata 013.jpg"),
    thumbnail: require("../assets/galerija/thumbs/tata 013_thumb.jpg"),
    category: "DNEVNI BORAVCI"
  },{
    original: require("../assets/galerija/main/KOMODE/DSC02760.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC02760_thumb.JPG"),
    category: "KOMODE"
  },,{
    original: require("../assets/galerija/main/KOMODE/DSC03902.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC03902_thumb.JPG"),
    category: "KOMODE"
  },,{
    original: require("../assets/galerija/main/KOMODE/DSC05313.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC05313_thumb.JPG"),
    category: "KOMODE"
  },,{
    original: require("../assets/galerija/main/KOMODE/DSC05314.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC05314_thumb.JPG"),
    category: "KOMODE"
  },,{
    original: require("../assets/galerija/main/KOMODE/SV263455.JPG"),
    thumbnail: require("../assets/galerija/thumbs/SV263455_thumb.JPG"),
    category: "KOMODE"
  },,{
    original: require("../assets/galerija/main/KOMODE/WP_20151204_009.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20151204_009_thumb.jpg"),
    category: "KOMODE"
  },,{
    original: require("../assets/galerija/main/KOMODE/WP_20160423_015.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20160423_015_thumb.jpg"),
    category: "KOMODE"
  },,{
    original: require("../assets/galerija/main/KOMODE/WP_20160423_023.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20160423_023_thumb.jpg"),
    category: "KOMODE"
  },,{
    original: require("../assets/galerija/main/KOMODE/WP_20161122_003.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20161122_003_thumb.jpg"),
    category: "KOMODE"
  },,{
    original: require("../assets/galerija/main/KOMODE/WP_20161122_004.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20161122_004_thumb.jpg"),
    category: "KOMODE"
  },,{
    original: require("../assets/galerija/main/KOMODE/WP_20161122_005.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20161122_005_thumb.jpg"),
    category: "KOMODE"
  },,{
    original: require("../assets/galerija/main/KOMODE/WP_20170525_16_14_09_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170525_16_14_09_Pro_thumb.jpg"),
    category: "KOMODE"
  },,{
    original: require("../assets/galerija/main/KOMODE/WP_20170525_16_14_16_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170525_16_14_16_Pro_thumb.jpg"),
    category: "KOMODE"
  },,{
    original: require("../assets/galerija/main/KOMODE/WP_20170628_10_42_07_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170628_10_42_07_Pro_thumb.jpg"),
    category: "KOMODE"
  },,{
    original: require("../assets/galerija/main/KOMODE/WP_20171115_13_36_05_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20171115_13_36_05_Pro_thumb.jpg"),
    category: "KOMODE"
  },{
    original: require("../assets/galerija/main/KREVETI/DSC02753.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC02753_thumb.JPG"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/DSC02758.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC02758_thumb.JPG"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/DSC02759.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC02759_thumb.JPG"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/DSC02762.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC02762_thumb.JPG"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/DSC02816.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC02816_thumb.JPG"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/DSC04789.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC04789_thumb.JPG"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/IMG_4156.jpg"),
    thumbnail: require("../assets/galerija/thumbs/IMG_4156_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/IMG_4157.jpg"),
    thumbnail: require("../assets/galerija/thumbs/IMG_4157_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/IMG_4160.jpg"),
    thumbnail: require("../assets/galerija/thumbs/IMG_4160_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/IMG_4163.jpg"),
    thumbnail: require("../assets/galerija/thumbs/IMG_4163_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/IMG_4165.jpg"),
    thumbnail: require("../assets/galerija/thumbs/IMG_4165_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/Pictures 159.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Pictures 159_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/Pictures 167.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Pictures 167_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/Pictures 223.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Pictures 223_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/Pictures 230.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Pictures 230_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/Pictures 231.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Pictures 231_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/Pictures 232.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Pictures 232_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/Pictures 233.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Pictures 233_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/Pictures 266.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Pictures 266_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/Pictures 267.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Pictures 267_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/Pictures 268.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Pictures 268_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/Pictures 271.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Pictures 271_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/Pictures 275.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Pictures 275_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/Pictures 354.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Pictures 354_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/Pictures 356.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Pictures 356_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20140214_002.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20140214_002_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20151123_002.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20151123_002_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20151123_005.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20151123_005_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20151204_013.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20151204_013_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20160311_004.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20160311_004_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20161115_006.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20161115_006_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20161115_009.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20161115_009_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20161216_001.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20161216_001_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20161222_002.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20161222_002_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20161222_004.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20161222_004_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20161222_006.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20161222_006_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20170407_16_30_41_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170407_16_30_41_Pro_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20170407_16_50_36_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170407_16_50_36_Pro_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20170509_17_05_00_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170509_17_05_00_Pro_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20170509_17_08_50_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170509_17_08_50_Pro_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20170519_11_53_05_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170519_11_53_05_Pro_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20170714_09_51_27_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170714_09_51_27_Pro_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20170714_09_51_55_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170714_09_51_55_Pro_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20171115_13_33_28_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20171115_13_33_28_Pro_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20180615_13_48_10_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180615_13_48_10_Pro_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20180615_13_48_48_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180615_13_48_48_Pro_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20181012_15_27_10_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20181012_15_27_10_Pro_thumb.jpg"),
    category: "KREVETI"
  },,{
    original: require("../assets/galerija/main/KREVETI/WP_20181012_15_27_19_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20181012_15_27_19_Pro_thumb.jpg"),
    category: "KREVETI"
  },{
    original: require("../assets/galerija/main/KUHINJE/DSC04036.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC04036_thumb.JPG"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/DSC04037.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC04037_thumb.JPG"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/DSC04042.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC04042_thumb.JPG"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/DSC04044.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC04044_thumb.JPG"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/DSC05201.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC05201_thumb.JPG"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/IMG_4193.jpg"),
    thumbnail: require("../assets/galerija/thumbs/IMG_4193_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/IMG_4194.jpg"),
    thumbnail: require("../assets/galerija/thumbs/IMG_4194_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/IMG_4196.jpg"),
    thumbnail: require("../assets/galerija/thumbs/IMG_4196_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/IMG_4199.jpg"),
    thumbnail: require("../assets/galerija/thumbs/IMG_4199_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/IMG_4202.jpg"),
    thumbnail: require("../assets/galerija/thumbs/IMG_4202_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/IMG_4203.jpg"),
    thumbnail: require("../assets/galerija/thumbs/IMG_4203_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/IMG_4210.jpg"),
    thumbnail: require("../assets/galerija/thumbs/IMG_4210_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/IMG_4212.jpg"),
    thumbnail: require("../assets/galerija/thumbs/IMG_4212_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/IMG_4213.jpg"),
    thumbnail: require("../assets/galerija/thumbs/IMG_4213_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/IMG_4215.jpg"),
    thumbnail: require("../assets/galerija/thumbs/IMG_4215_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/Pictures 234.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Pictures 234_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20130920_004.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20130920_004_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20130920_006.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20130920_006_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20140618_001.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20140618_001_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20140618_003.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20140618_003_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20140618_004.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20140618_004_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20141216_006.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20141216_006_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20141216_007.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20141216_007_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20141216_010.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20141216_010_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20141219_003.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20141219_003_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20141219_004.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20141219_004_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20150209_001.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20150209_001_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20150722_003.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20150722_003_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20151204_007.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20151204_007_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20160311_005.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20160311_005_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20160423_028.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20160423_028_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20160423_029.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20160423_029_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20161115_005.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20161115_005_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20170407_18_35_47_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170407_18_35_47_Pro_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20170407_18_36_43_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170407_18_36_43_Pro_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20170407_18_37_43_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170407_18_37_43_Pro_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20170509_17_55_16_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170509_17_55_16_Pro_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20170509_17_55_37_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170509_17_55_37_Pro_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20170509_17_56_20_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170509_17_56_20_Pro_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20170509_18_02_10_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170509_18_02_10_Pro_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20170509_18_02_31_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170509_18_02_31_Pro_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20170526_15_03_57_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170526_15_03_57_Pro_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20170616_16_57_50_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170616_16_57_50_Pro_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20170616_16_58_09_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170616_16_58_09_Pro_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20171006_11_59_57_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20171006_11_59_57_Pro_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20171006_12_04_23_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20171006_12_04_23_Pro_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20180301_14_02_04_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180301_14_02_04_Pro_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20180321_13_31_58_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180321_13_31_58_Pro_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/WP_20180321_13_32_03_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180321_13_32_03_Pro_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/tata 020.jpg"),
    thumbnail: require("../assets/galerija/thumbs/tata 020_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/tata 021.jpg"),
    thumbnail: require("../assets/galerija/thumbs/tata 021_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/tata 022.jpg"),
    thumbnail: require("../assets/galerija/thumbs/tata 022_thumb.jpg"),
    category: "KUHINJE"
  },,{
    original: require("../assets/galerija/main/KUHINJE/tata 023.jpg"),
    thumbnail: require("../assets/galerija/thumbs/tata 023_thumb.jpg"),
    category: "KUHINJE"
  },{
    original: require("../assets/galerija/main/KUPAONE/WP_20170712_15_53_06_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170712_15_53_06_Pro_thumb.jpg"),
    category: "KUPAONE"
  },,{
    original: require("../assets/galerija/main/KUPAONE/WP_20170712_15_53_29_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170712_15_53_29_Pro_thumb.jpg"),
    category: "KUPAONE"
  },,{
    original: require("../assets/galerija/main/KUPAONE/WP_20170712_15_54_08_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170712_15_54_08_Pro_thumb.jpg"),
    category: "KUPAONE"
  },,{
    original: require("../assets/galerija/main/KUPAONE/WP_20170712_16_17_26_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170712_16_17_26_Pro_thumb.jpg"),
    category: "KUPAONE"
  },{
    original: require("../assets/galerija/main/ORMARI/BOTO I TATA 104.jpg"),
    thumbnail: require("../assets/galerija/thumbs/BOTO I TATA 104_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/Copy of tata 001.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Copy of tata 001_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/Copy of tata 003.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Copy of tata 003_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/DSC00370.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC00370_thumb.JPG"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/DSC00371.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC00371_thumb.JPG"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/DSC00374.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC00374_thumb.JPG"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/DSC02763.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC02763_thumb.JPG"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/DSC02813.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC02813_thumb.JPG"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/DSC02815.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC02815_thumb.JPG"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/DSC04049.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC04049_thumb.JPG"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/DSC04052.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC04052_thumb.JPG"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/DSC05234.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC05234_thumb.JPG"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/Pictures 151.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Pictures 151_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/Pictures 152.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Pictures 152_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/Pictures 221.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Pictures 221_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/Pictures 358.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Pictures 358_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/Pictures 360.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Pictures 360_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/Slika053.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Slika053_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20141216_016.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20141216_016_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20150527_006.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20150527_006_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20150529_006.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20150529_006_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20151123_001.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20151123_001_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20151204_010.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20151204_010_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20151204_011.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20151204_011_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20151204_019.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20151204_019_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20151221_004.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20151221_004_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20160309_002.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20160309_002_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20160311_002.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20160311_002_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20160423_018.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20160423_018_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20160423_022.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20160423_022_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20160423_025.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20160423_025_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20160607_003.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20160607_003_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20161026_001.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20161026_001_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20161026_004.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20161026_004_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20161215_002.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20161215_002_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20161215_010.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20161215_010_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20161215_011.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20161215_011_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170329_12_50_22_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170329_12_50_22_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170329_12_50_41_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170329_12_50_41_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170407_16_29_20_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170407_16_29_20_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170407_16_29_54_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170407_16_29_54_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170407_16_32_00_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170407_16_32_00_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170407_16_33_16_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170407_16_33_16_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170407_16_33_29_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170407_16_33_29_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170407_16_47_27_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170407_16_47_27_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170509_17_03_48_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170509_17_03_48_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170509_17_04_16_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170509_17_04_16_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170509_17_05_18_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170509_17_05_18_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170509_17_06_44_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170509_17_06_44_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170509_17_10_15_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170509_17_10_15_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170519_11_29_10_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170519_11_29_10_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170519_11_52_12_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170519_11_52_12_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170522_10_51_58_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170522_10_51_58_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170522_10_52_14_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170522_10_52_14_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170526_14_30_28_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170526_14_30_28_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170526_14_30_44_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170526_14_30_44_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170612_14_00_13_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170612_14_00_13_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170612_14_00_28_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170612_14_00_28_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170612_17_42_28_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170612_17_42_28_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170612_17_44_59_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170612_17_44_59_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170620_13_53_51_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170620_13_53_51_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170620_14_17_00_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170620_14_17_00_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170620_14_17_36_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170620_14_17_36_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170620_14_18_02_Pro (2).jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170620_14_18_02_Pro (2)_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170620_14_23_02_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170620_14_23_02_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20170908_12_13_03_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170908_12_13_03_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20171005_16_31_29_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20171005_16_31_29_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20171005_16_36_22_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20171005_16_36_22_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20171005_16_37_14_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20171005_16_37_14_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20171005_16_37_39_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20171005_16_37_39_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20171006_12_01_43_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20171006_12_01_43_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20171115_12_05_04_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20171115_12_05_04_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20171201_14_07_22_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20171201_14_07_22_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20180316_16_03_27_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180316_16_03_27_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20180321_13_31_37_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180321_13_31_37_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20180412_12_03_56_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180412_12_03_56_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20180412_12_09_11_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180412_12_09_11_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20180417_13_34_43_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180417_13_34_43_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20180514_16_24_33_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180514_16_24_33_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20180720_15_15_52_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180720_15_15_52_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20180720_16_04_31_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180720_16_04_31_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20180828_14_47_36_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180828_14_47_36_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20180828_16_26_18_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180828_16_26_18_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20180828_16_26_44_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180828_16_26_44_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20181107_13_43_13_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20181107_13_43_13_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20181113_12_15_14_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20181113_12_15_14_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20181113_13_45_01_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20181113_13_45_01_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20181113_15_01_49_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20181113_15_01_49_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/WP_20181113_15_01_50_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20181113_15_01_50_Pro_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/ZOO 2008 008.jpg"),
    thumbnail: require("../assets/galerija/thumbs/ZOO 2008 008_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/ZOO 2008 009.jpg"),
    thumbnail: require("../assets/galerija/thumbs/ZOO 2008 009_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/ZOO 2008 010.jpg"),
    thumbnail: require("../assets/galerija/thumbs/ZOO 2008 010_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/ZOO 2008 011.jpg"),
    thumbnail: require("../assets/galerija/thumbs/ZOO 2008 011_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/oramr.jpg"),
    thumbnail: require("../assets/galerija/thumbs/oramr_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/sve slike 260.jpg"),
    thumbnail: require("../assets/galerija/thumbs/sve slike 260_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/tata i krizma 200.jpg"),
    thumbnail: require("../assets/galerija/thumbs/tata i krizma 200_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/tata i krizma 201.jpg"),
    thumbnail: require("../assets/galerija/thumbs/tata i krizma 201_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/tata prosinac 2009 043.jpg"),
    thumbnail: require("../assets/galerija/thumbs/tata prosinac 2009 043_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/tata prosinac 2009 048.jpg"),
    thumbnail: require("../assets/galerija/thumbs/tata prosinac 2009 048_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/tata prosinac 2009 052.jpg"),
    thumbnail: require("../assets/galerija/thumbs/tata prosinac 2009 052_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/tata prosinac 2009 060.jpg"),
    thumbnail: require("../assets/galerija/thumbs/tata prosinac 2009 060_thumb.jpg"),
    category: "ORMARI"
  },,{
    original: require("../assets/galerija/main/ORMARI/tata prosinac 2009 061.jpg"),
    thumbnail: require("../assets/galerija/thumbs/tata prosinac 2009 061_thumb.jpg"),
    category: "ORMARI"
  },{
    original: require("../assets/galerija/main/OSTALO/DSC02800.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC02800_thumb.JPG"),
    category: "OSTALO"
  },,{
    original: require("../assets/galerija/main/OSTALO/DSC02803.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC02803_thumb.JPG"),
    category: "OSTALO"
  },,{
    original: require("../assets/galerija/main/OSTALO/DSC03013.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC03013_thumb.JPG"),
    category: "OSTALO"
  },,{
    original: require("../assets/galerija/main/OSTALO/DSC03014.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC03014_thumb.JPG"),
    category: "OSTALO"
  },,{
    original: require("../assets/galerija/main/OSTALO/DSC04066.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC04066_thumb.JPG"),
    category: "OSTALO"
  },,{
    original: require("../assets/galerija/main/OSTALO/DSC04067.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC04067_thumb.JPG"),
    category: "OSTALO"
  },,{
    original: require("../assets/galerija/main/OSTALO/DSC05274.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC05274_thumb.JPG"),
    category: "OSTALO"
  },,{
    original: require("../assets/galerija/main/OSTALO/DSC05278.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC05278_thumb.JPG"),
    category: "OSTALO"
  },,{
    original: require("../assets/galerija/main/OSTALO/WP_20151002_001.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20151002_001_thumb.jpg"),
    category: "OSTALO"
  },,{
    original: require("../assets/galerija/main/OSTALO/WP_20151002_008.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20151002_008_thumb.jpg"),
    category: "OSTALO"
  },,{
    original: require("../assets/galerija/main/OSTALO/WP_20151002_011.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20151002_011_thumb.jpg"),
    category: "OSTALO"
  },,{
    original: require("../assets/galerija/main/OSTALO/WP_20151221_006.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20151221_006_thumb.jpg"),
    category: "OSTALO"
  },,{
    original: require("../assets/galerija/main/OSTALO/WP_20160214_001.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20160214_001_thumb.jpg"),
    category: "OSTALO"
  },,{
    original: require("../assets/galerija/main/OSTALO/WP_20170707_15_57_48_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170707_15_57_48_Pro_thumb.jpg"),
    category: "OSTALO"
  },,{
    original: require("../assets/galerija/main/OSTALO/WP_20180413_18_15_49_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180413_18_15_49_Pro_thumb.jpg"),
    category: "OSTALO"
  },,{
    original: require("../assets/galerija/main/OSTALO/WP_20180413_18_16_00_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180413_18_16_00_Pro_thumb.jpg"),
    category: "OSTALO"
  },,{
    original: require("../assets/galerija/main/OSTALO/WP_20181113_13_51_26_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20181113_13_51_26_Pro_thumb.jpg"),
    category: "OSTALO"
  },,{
    original: require("../assets/galerija/main/OSTALO/WP_20181113_13_51_43_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20181113_13_51_43_Pro_thumb.jpg"),
    category: "OSTALO"
  },,{
    original: require("../assets/galerija/main/OSTALO/tata svasta 080.jpg"),
    thumbnail: require("../assets/galerija/thumbs/tata svasta 080_thumb.jpg"),
    category: "OSTALO"
  },{
    original: require("../assets/galerija/main/PROZORI/DSC02249.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC02249_thumb.JPG"),
    category: "PROZORI"
  },,{
    original: require("../assets/galerija/main/PROZORI/DSC03555.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC03555_thumb.JPG"),
    category: "PROZORI"
  },,{
    original: require("../assets/galerija/main/PROZORI/DSC05209.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC05209_thumb.JPG"),
    category: "PROZORI"
  },,{
    original: require("../assets/galerija/main/PROZORI/DSC05210.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC05210_thumb.JPG"),
    category: "PROZORI"
  },,{
    original: require("../assets/galerija/main/PROZORI/DSC05211.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC05211_thumb.JPG"),
    category: "PROZORI"
  },{
    original: require("../assets/galerija/main/STOLOVI I STOLICE/DSC04790.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC04790_thumb.JPG"),
    category: "STOLOVI I STOLICE"
  },,{
    original: require("../assets/galerija/main/STOLOVI I STOLICE/DSC05214.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC05214_thumb.JPG"),
    category: "STOLOVI I STOLICE"
  },,{
    original: require("../assets/galerija/main/STOLOVI I STOLICE/DSC05222.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC05222_thumb.JPG"),
    category: "STOLOVI I STOLICE"
  },,{
    original: require("../assets/galerija/main/STOLOVI I STOLICE/DSC05225.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC05225_thumb.JPG"),
    category: "STOLOVI I STOLICE"
  },,{
    original: require("../assets/galerija/main/STOLOVI I STOLICE/DSC05332.jpg"),
    thumbnail: require("../assets/galerija/thumbs/DSC05332_thumb.jpg"),
    category: "STOLOVI I STOLICE"
  },,{
    original: require("../assets/galerija/main/STOLOVI I STOLICE/DSC05333.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC05333_thumb.JPG"),
    category: "STOLOVI I STOLICE"
  },,{
    original: require("../assets/galerija/main/STOLOVI I STOLICE/DSC05334.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC05334_thumb.JPG"),
    category: "STOLOVI I STOLICE"
  },,{
    original: require("../assets/galerija/main/STOLOVI I STOLICE/DSC05335.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC05335_thumb.JPG"),
    category: "STOLOVI I STOLICE"
  },,{
    original: require("../assets/galerija/main/STOLOVI I STOLICE/Stol_1_s.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Stol_1_s_thumb.jpg"),
    category: "STOLOVI I STOLICE"
  },,{
    original: require("../assets/galerija/main/STOLOVI I STOLICE/WP_20141216_018.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20141216_018_thumb.jpg"),
    category: "STOLOVI I STOLICE"
  },,{
    original: require("../assets/galerija/main/STOLOVI I STOLICE/WP_20141216_023.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20141216_023_thumb.jpg"),
    category: "STOLOVI I STOLICE"
  },,{
    original: require("../assets/galerija/main/STOLOVI I STOLICE/WP_20151002_014.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20151002_014_thumb.jpg"),
    category: "STOLOVI I STOLICE"
  },,{
    original: require("../assets/galerija/main/STOLOVI I STOLICE/WP_20170407_16_34_03_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170407_16_34_03_Pro_thumb.jpg"),
    category: "STOLOVI I STOLICE"
  },,{
    original: require("../assets/galerija/main/STOLOVI I STOLICE/WP_20170419_13_45_01_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170419_13_45_01_Pro_thumb.jpg"),
    category: "STOLOVI I STOLICE"
  },,{
    original: require("../assets/galerija/main/STOLOVI I STOLICE/WP_20180504_11_07_35_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180504_11_07_35_Pro_thumb.jpg"),
    category: "STOLOVI I STOLICE"
  },{
    original: require("../assets/galerija/main/UREDI/WP_20150119_002.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20150119_002_thumb.jpg"),
    category: "UREDI"
  },,{
    original: require("../assets/galerija/main/UREDI/WP_20150119_003.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20150119_003_thumb.jpg"),
    category: "UREDI"
  },,{
    original: require("../assets/galerija/main/UREDI/WP_20180427_12_57_02_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180427_12_57_02_Pro_thumb.jpg"),
    category: "UREDI"
  },,{
    original: require("../assets/galerija/main/UREDI/WP_20180427_12_57_17_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180427_12_57_17_Pro_thumb.jpg"),
    category: "UREDI"
  },{
    original: require("../assets/galerija/main/VRATA/BOTO I TATA 115.jpg"),
    thumbnail: require("../assets/galerija/thumbs/BOTO I TATA 115_thumb.jpg"),
    category: "VRATA"
  },,{
    original: require("../assets/galerija/main/VRATA/Copy (2) of ZOO 2008 220 - Copy.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Copy (2) of ZOO 2008 220 - Copy_thumb.jpg"),
    category: "VRATA"
  },,{
    original: require("../assets/galerija/main/VRATA/Copy of ZOO 2008 227.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Copy of ZOO 2008 227_thumb.jpg"),
    category: "VRATA"
  },,{
    original: require("../assets/galerija/main/VRATA/DSC02252.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC02252_thumb.JPG"),
    category: "VRATA"
  },,{
    original: require("../assets/galerija/main/VRATA/DSC02795.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC02795_thumb.JPG"),
    category: "VRATA"
  },,{
    original: require("../assets/galerija/main/VRATA/DSC02796.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC02796_thumb.JPG"),
    category: "VRATA"
  },,{
    original: require("../assets/galerija/main/VRATA/DSC02798.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC02798_thumb.JPG"),
    category: "VRATA"
  },,{
    original: require("../assets/galerija/main/VRATA/DSC02802.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC02802_thumb.JPG"),
    category: "VRATA"
  },,{
    original: require("../assets/galerija/main/VRATA/DSC03471.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC03471_thumb.JPG"),
    category: "VRATA"
  },,{
    original: require("../assets/galerija/main/VRATA/DSC03472.JPG"),
    thumbnail: require("../assets/galerija/thumbs/DSC03472_thumb.JPG"),
    category: "VRATA"
  },,{
    original: require("../assets/galerija/main/VRATA/Picture 161.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Picture 161_thumb.jpg"),
    category: "VRATA"
  },,{
    original: require("../assets/galerija/main/VRATA/Picture 162.jpg"),
    thumbnail: require("../assets/galerija/thumbs/Picture 162_thumb.jpg"),
    category: "VRATA"
  },,{
    original: require("../assets/galerija/main/VRATA/WP_20140918_003.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20140918_003_thumb.jpg"),
    category: "VRATA"
  },,{
    original: require("../assets/galerija/main/VRATA/WP_20140918_007.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20140918_007_thumb.jpg"),
    category: "VRATA"
  },,{
    original: require("../assets/galerija/main/VRATA/WP_20160407_001.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20160407_001_thumb.jpg"),
    category: "VRATA"
  },,{
    original: require("../assets/galerija/main/VRATA/WP_20170411_17_32_07_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170411_17_32_07_Pro_thumb.jpg"),
    category: "VRATA"
  },,{
    original: require("../assets/galerija/main/VRATA/WP_20170914_14_04_28_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20170914_14_04_28_Pro_thumb.jpg"),
    category: "VRATA"
  },,{
    original: require("../assets/galerija/main/VRATA/WP_20180206_13_32_33_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180206_13_32_33_Pro_thumb.jpg"),
    category: "VRATA"
  },,{
    original: require("../assets/galerija/main/VRATA/WP_20180706_12_42_52_Pro.jpg"),
    thumbnail: require("../assets/galerija/thumbs/WP_20180706_12_42_52_Pro_thumb.jpg"),
    category: "VRATA"
  },,{
    original: require("../assets/galerija/main/VRATA/ZOO 2008 231.jpg"),
    thumbnail: require("../assets/galerija/thumbs/ZOO 2008 231_thumb.jpg"),
    category: "VRATA"
  },,{
    original: require("../assets/galerija/main/VRATA/ZOO 2008 232.jpg"),
    thumbnail: require("../assets/galerija/thumbs/ZOO 2008 232_thumb.jpg"),
    category: "VRATA"
  },]