import React from "react";
import Cell from "./UI/Cell";
import Section from "./UI/Section";
import Map from "./UI/Map";
import { IconSeparator, Grid } from "react-md";
import content from "../utils/content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { maps, contact } = content;

class Contact extends React.Component {
  state = {
    map: "radiona"
  };

  handleAddressClick = map => {
    this.setState({ map });
  };

  render() {
    const { title, src } = maps[this.state.map];
    const fbLink = (
      <a
        href="https://www.facebook.com/Stolarija-Banek-852089148146167/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Facebook
      </a>
    );

    return (
      <Section id="Kontakt" title="Kontakt">
        <Grid className="contacts">
          <Cell size={4} tabletSize={6} className="info">
            <div>
              {contact.info.map(info => {
                let style;
                if (info.tag) {
                  style = { cursor: "pointer" };
                }
                return (
                  <IconSeparator
                    key={info.label}
                    label={info.label}
                    iconBefore
                    style={style}
                    onClick={() =>
                      info.tag && this.handleAddressClick(info.tag)
                    }
                  >
                    <div style={{ width: "20px", textAlign: "center" }}>
                      <FontAwesomeIcon icon={info.icon} />
                    </div>
                  </IconSeparator>
                );
              })}
              <IconSeparator
                key="fb-link"
                label={fbLink}
                iconBefore
                // style={style}
                // onClick={() => this.handleAddressClick(info.tag)}
              >
                <div style={{ width: "20px", textAlign: "center" }}>
                  <FontAwesomeIcon icon={contact.facebook.src} />
                </div>
              </IconSeparator>
            </div>
          </Cell>
          <Cell size={4} tabletSize={6}>
            <Map title={title} src={src} />
          </Cell>
        </Grid>
      </Section>
    );
  }
}

export default Contact;
