import React from "react";
import { Button } from "react-md";
import Scrollchor from "react-scrollchor";
import content from "../utils/content";
import logo from "../assets/banner-logo.png";

const { sections, scrollAnimate } = content;

const Banner = props => {
  return (
    // <div id="Home" className="main">
    <div className="banner">
      {/* <div className="title white-text"> */}
      <img className="banner-logo" src={logo} alt="logo" />
      <div className="nav">
        {sections.map(({ title }) => (
          <Scrollchor key={title} to={"#" + title} animate={scrollAnimate}>
            <Button flat key={title}>
              {title}
            </Button>
          </Scrollchor>
        ))}
      </div>
    </div>
  );
};

export default Banner;
