import React from "react";
import { Toolbar, IconSeparator, FontIcon } from "react-md";
import content from "../utils/content";

const { label, icon } = content.footer;

const Footer = () => {
  return (
    <Toolbar colored className="footer">
      <IconSeparator iconBefore label={label}>
        <FontIcon>{icon}</FontIcon>
      </IconSeparator>
    </Toolbar>
  );
};

export default Footer;
