import React from "react";
import YouTube from "react-youtube-embed";
import { Grid } from "react-md";
import Cell from "./UI/Cell";
import Section from "./UI/Section";
import Showcase from "./UI/Showcase";
import content from "../utils/content";

const { icon, label, desc, special } = content.awards;

const About = () => {
  return (
    <Section id="O nama" title="O nama">
      <Grid className="md-grid">
        <Cell size={8} tabletSize={12}>
          <div className="md-text-left">
            Stolarija Banek tradicijski je obrt osnovan 1919 g. u Gračanima i od
            tada posluje u kontinuitetu. Stolarski obrt osnovao je Rudolf Banek
            (rođen 1886 g.) 1919. godine. Zatim ga je naslijedio sin, također
            Rudolf Banek (rođen 1929. g.) koji je zanat naučio od njega,
            proširio gospodarsku zgradu te nastavio raditi i 1957. g. preuzeo
            stolarski obrt od svojeg oca mog pradjeda. Godine 1986. obrt je
            preuzeo moj otac Rudolf (rođen 1964.g.) koji je zanat naučio od
            djeda, modernizirao radionicu i povećao broj proizvoda i djelatnika
            te je prenio znanje ne mene Rudolfa Baneka. Godine 2015. preuzeo sam
            obrt “Stolarija Banek" kao 4. generacija stolara u obitelji i od
            tada se trudim održati tradiciju. Obrt “Stolarija Banek” na praksi
            ima tri praktikanta/naučnika pošto mladima želimo prenijeti znanje i
            dati mogućnost brzog zaposlenja. Radionica se i dalje nalazi na
            istoj adresi kao i 1919. godine u Gračanima na adresi Grabeščak 8.
            Sva dobivena priznanja, vaše zadovoljstvo našim proizvodima, usmena
            preporuka, sretni djelatnici i praktikanti poticaj su i motiv da
            budemo još bolji i časniji obrtnici i sljedećih 100 godina.
          </div>
          <div className="video">
            <YouTube id="M-Kx9QLjtjY" />
          </div>
        </Cell>
        <Cell size={4} tabletSize={6} tabletOffset={1}>
          <Showcase icon={icon} label={label}>
            <div className="md-text-left">
              {desc.map((award, idx) => {
                const { title, text } = award;
                return (
                  <div key={idx}>
                    <span className="md-font-bold">{`${title} `}</span>
                    <span>{text}</span>
                  </div>
                );
              })}
              <div className="award_special md-font-bold">{special}</div>
            </div>
          </Showcase>
        </Cell>
      </Grid>
    </Section>
  );
};

export default About;
