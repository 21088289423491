import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import {
  faClock,
  faCog,
  faHandPaper,
  faTrophy,
  faPhone,
  faMobile,
  faShoppingCart,
  faEnvelope,
  faMapMarker
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faClock,
  faCog,
  faHandPaper,
  faTrophy,
  faPhone,
  faMobile,
  faShoppingCart,
  faEnvelope,
  faMapMarker,
  faFacebook
);

const content = {
  scrollAnimate: {
    offset: 0,
    duration: 350
  },
  appTitle: "Stolarija Banek",
  sections: [
    {
      title: "O nama"
    },
    {
      title: "Proizvodi"
    },
    {
      title: "Kontakt"
    }
  ],
  products: [
    {
      label: "Tradicija",
      title: "1919.",
      desc:
        "Od 1919. godine nudimo široki asortiman proizvoda od interijera pa sve do građevne stolarije. U mogućnosti smo se posvetiti kako individualnom tako i organiziranom kupcu te poduzećima sa zahtjevnijim i većim narudžbama. Uz navedene proizvode izrađujemo i sakralnu stolariju."
    },
    {
      label: "Izrada po mjeri",
      icon: "cog",
      desc:
        "Puno puta se događa da vidite neki namještaj i da Vam se jako svidi, ali imate problem da ne stane u prostor gdje bi Vi to željeli. Izrada po mjeri je velika prednost jer osim vizualnog, namještaj mora zadovoljit estetske i funkcionalne standarde. Prije same izrade vršimo mjerenje i tek onda izrađujemo namještaj."
    },
    {
      label: "Ručni rad",
      icon: "hand-paper",
      desc:
        "U današnje vrijeme velika je dostupnost instant proizvoda. Takvi proizvodi upitne su kvalitete. Mi već dugi niz godina izrađujemo proizvode ručnim radom i od provjerenih materijala, što je ujedno i garancija visoke kvalitete i dugotrajnosti naših proizvoda."
    }
  ],
  awards: {
    icon: "trophy",
    label: "Priznanja",
    special:
      "Posebno smo ponosni na sajamsku nagradu Mobil optimum za kvalitetu izrade okvirne konstrukcije kreveta.",
    desc: [
      {
        title: "Zlatne ruke",
        text: "- najviše priznanje Hrvatske obrtničke komore"
      },
      {
        title: "Zlatna diploma od 'OKZ'",
        text: "za doprinos u očuvanju tradicije obrta i obrazovanju naučnika"
      },
      {
        title: "Zlatna plaketa od 'UOGZ'",
        text:
          "za dugogodišnji rad, poslovne uspjehe i doprinosu u razvoju obrtništva"
      },
      {
        title: "Platinasti obrt od 'UOGZ'",
        text: " za očuvanje i nastavak obiteljske tradicije preko 60 godina"
      },
      {
        title: "Zlatna medalja grada Zagreba",
        text:
          "za obrt stariji od 50 godina koji promiče obrtništvo grada Zagreba"
      }
    ]
  },
  footer: {
    label: new Date().getFullYear() + " Stolarija Banek. Sva prava pridržana.",
    icon: "copyright"
  },
  maps: {
    radiona: {
      title: "map-radiona",
      src:
        "https://www.google.com/maps/embed/v1/place?q=Grabeščak+8,+10000,+Zagreb,+Croatia&key="
    },
    prodaja: {
      title: "map-prodaja",
      src:
        "https://www.google.com/maps/embed/v1/place?q=zagrebacki%20velesajam&key="
    }
  },
  contact: {
    facebook: {
      src: ["fab", "facebook"]
    },
    info: [
      {
        icon: "phone",
        label: "+385 (0)1 4645 749"
      },
      {
        icon: "mobile",
        label: "+385 (0)91 200 8526"
      },
      {
        icon: "envelope",
        label: "rudolfbanek@hotmail.com"
      },
      {
        icon: "map-marker",
        label: "Grabešćak 8, 10000 Zagreb",
        tag: "radiona"
      },
      {
        icon: "shopping-cart",
        label: "Zagrebački velesajam, paviljon 12",
        tag: "prodaja"
      }
    ],
    address: ["iuhpiuh", "Izgozgo"],
    social: [
      {
        icon: "g78t9g76g",
        url: "87g9g"
      },
      {
        icon: "g78t9g76g",
        url: "87g9g"
      },
      {
        icon: "g78t9g76g",
        url: "87g9g"
      }
    ]
  }
};

export const FILTER_OPTIONS = {
  SHOW_ALL: "PRIKAŽI SVE",
  DNEVNI: "BORAVCI",
  KOMODE: "KOMODE",
  KREVETI: "KREVETI",
  KUHINJE: "KUHINJE",
  KUPAONE: "KUPAONE",
  ORMARI: "ORMARI",
  OSTALO: "OSTALO",
  PROZORI: "PROZORI",
  STOLOVI_STOLICE: "STOLOVI I STOLICE",
  UREDI: "UREDI",
  VRATA: "VRATA"
};

export default content;
