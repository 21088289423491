import React from "react";

const Section = props => {
  const { title, children } = props;
  return (
    <div className="section">
      <div className="section-container">
        <div
          className="md-display-3 md-text-left"
          style={{ paddingLeft: "20px" }}
          {...props}
        >
          {title}
        </div>
        <div style={{ fontSize: "1.1em" }}>{children}</div>
      </div>
    </div>
  );
};

export default Section;
